import CountryCard from './CountryCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import CountryListUtil,{ICountryListDataObj} from './countryListUtil';
import language from './countryLanguage'
export default defineComponent ({
    name: 'CountryList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:ICountryListDataObj=reactive<ICountryListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: CountryCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/country/pageData')
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new CountryListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed------------

        const formatPageInfo=(options:any)=>{
            //找到需要格式化的列
            //options.pageInfo.columns.forEach((item:any)=>{
            //    if(item.prop=='F_DY_FLAG')item.formatter=utils.UtilPub.formatterYesNoStatus;
            //})
            return options;
        }

        return{
            ...toRefs(dataObj),formatPageInfo
        }
    }
});