import BaseBean from "@/utils/BaseBean";

export interface ICountryListDataObj {
    utilInst:CountryListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
}

export default class CountryListUtil extends BaseBean{
    public dataObj:ICountryListDataObj

    constructor(proxy:any,dataObj:ICountryListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}